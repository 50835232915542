
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/tijdschriften/[magazineId]",
      function () {
        return require("private-next-pages/tijdschriften/[magazineId]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/tijdschriften/[magazineId]"])
      });
    }
  